// Library imports
import { navigate } from 'gatsby';

// Route users attempting to access 5.0 or 4.9 docs to the docs4 site.
// Route created due to moving 5.0 to docs4
export const onPreRouteUpdate = ({ location }) => {
  if (location.pathname.includes('5.0') || location.pathname.includes('4.9')) {
    navigate('https://docs4.incorta.com' + location.pathname);
  }
};
